<template>
    <div class="content ins-content" v-loading="loading">

        <div class="ins">
            <breads :breads="breads"></breads>
            <div class="ins-title">
                <div class="title-box">
                    <div class="title">
                        {{ res.cname }}
                        <span class="quick-access" @click="quickAccessClick" v-show="false">
                        <img src="@static/img/share_my.svg" class="access-icon"/>快速访问</span>
                    </div>

                    <div class="dept" v-if="res.platformName">
                        所属平台：
                        <span class="dept-name">{{ res.platformName }}</span>
                    </div>
                </div>
                <div class="tool-box">
                    <div class="tool-item" @click="collectClick">
                        <img v-if="!isCollect" src="@static/img/icons8-heart.svg" class="tool-icon"/>
                        <img v-else src="@static/img/icons8-heart-select.svg" class="tool-icon"/>
                        <span class="tool-text">收藏</span>
                    </div>
                    <div class="tool-item" @click="shareClick">
                        <img src="@static/img/icons8-share.svg" class="tool-icon"/>
                        <span class="tool-text">分享</span>
                    </div>
                    <div class="share-list" v-if="showShare">
                        <div class="share-item share-item-qq" @click="qqClick" @mouseover="isSelectQQ=true"
                             @mouseout="isSelectQQ=false">
                            <img v-if="!isSelectQQ" src="@static/img/qq.svg" class="share-icon"/>
                            <img v-else src="@static/img/qq-select.svg" class="share-icon"/>
                        </div>
                        <div class="share-item share-item-weibo" @click="weiboClick" @mouseover="isSelectWeibo=true"
                             @mouseout="isSelectWeibo=false">
                            <img v-if="!isSelectWeibo" src="@static/img/weibo.svg" class="share-icon"/>
                            <img v-else src="@static/img/weibo-select.svg" class="share-icon"/>
                        </div>
                        <el-popover placement="top" :width="200" trigger="hover" title="微信扫一扫：分享" @show="wechatClick">
                            <template #reference>
                                <div class="share-item share-item-wechat" @mouseover="isSelectWechat=true"
                                     @mouseout="isSelectWechat=false">
                                    <img v-if="!isSelectWechat" src="@static/img/wechat.svg" class="share-icon"/>
                                    <img v-else src="@static/img/wechat-select.svg" class="share-icon "/>
                                </div>
                            </template>
                            <div class="qrcode-box">
                                <qrcode-vue :value="qrcodeUrl" :size="110" level="H" class="qrcode-img"></qrcode-vue>
                                <div class="help"><span>微信里点“发现”，扫一下</span><br/><span>二维码便可将本文分享至朋友圈。</span></div>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>

            <!-- 仪器数据 展示 -->
            <table class="table ins-table" v-if="res.catalogType == 'INSTRUMENT_DATA'">


                <tr>
                    <td>仪器设备名称</td>
                    <td>{{ res.cname }}</td>
                </tr>
                <tr>
                    <td>英文名称</td>
                    <td>{{ res.ename }}</td>
                </tr>
                <tr>
                    <td>所属单位标识</td>
                    <td>{{ res.identifierCode }}</td>
                </tr>
                <tr>
                    <td>仪器所在单位内部编码</td>
                    <td>{{ res.insCode }}</td>
                </tr>
                <tr>
                    <td>所属资源载体名称</td>
                    <td>{{ res.instrBelongsType }}</td>
                </tr>
                <tr>
                    <td>所属资源载体单位内部编号</td>
                    <td>{{ res.instrBelongsName }}</td>
                </tr>
                <tr>
                    <td>仪器隶属平台（实验室）的名称</td>
                    <td>{{ res.resourceName }}</td>
                </tr>
                <tr>
                    <td>设备分类编码</td>
                    <td>{{ res.catalogCode }}</td>
                </tr>
                <tr>
                    <td>仪器设备来源</td>
                    <td>{{ res.instrSource }}</td>
                </tr>
                <tr>
                    <td>海关监管情况</td>
                    <td>{{ res.instrSupervise }}</td>
                </tr>
                <tr>
                    <td>原值（万元）</td>
                    <td>{{ res.worth }}</td>
                </tr>
                <tr>
                    <td>产地国别</td>
                    <td>{{ res.nationName }}</td>
                </tr>
                <tr>
                    <td>生产制造商</td>
                    <td>{{ res.manufacturer }}</td>
                </tr>
                <tr>
                    <td>建账日期</td>
                    <td>{{ res.beginDate }}</td>
                </tr>
                <tr>
                    <td>仪器设备类别</td>
                    <td>{{ res.deviceType }}</td>
                </tr>
                <tr>
                    <td>规格型号</td>
                    <td>{{ res.instrVersion }}</td>
                </tr>
                <tr>
                    <td>主要技术指标</td>
                    <td>{{ res.technical }}</td>
                </tr>
                <tr>
                    <td>主要功能</td>
                    <td>{{ res.function }}</td>
                </tr>
                <tr>
                    <td>主要学科领域</td>
                    <td>{{ res.subjectName }}</td>
                </tr>
                <tr>
                    <td>服务内容</td>
                    <td>{{ res.serviceContent }}</td>
                </tr>
                <tr>
                    <td>用户须知</td>
                    <td>{{ res.requirement }}</td>
                </tr>
                <tr>
                    <td>参考收费标准</td>
                    <td>{{ res.fee }}</td>
                </tr>
                <tr>
                    <td>预约服务网址</td>
                    <td>{{ res.serviceUrl }}</td>
                </tr>
                <tr>
                    <td>安放地址</td>
                    <td>{{ res.provinceName + res.cityName + res.countyName + res.street }}</td>
                </tr>
                <tr>
                    <td>邮政编码</td>
                    <td>{{ res.postalcode }}</td>
                </tr>
                <tr>
                    <td>仪器图片</td>
                    <td>{{ res.image }}</td>
                </tr>
                <tr>
                    <td>年总运行机时（小时）</td>
                    <td>{{ res.runMachine }}</td>
                </tr>
                <tr>
                    <td>年对外服务机时（小时）</td>
                    <td>{{ res.serviceMachine }}</td>
                </tr>
                <tr>
                    <td>主要购置经费来源</td>
                    <td>{{ res.funds }}</td>
                </tr>
                <tr>
                    <td>所属单位内部门</td>
                    <td>{{ res.insideDepart }}</td>
                </tr>

                <tr>
                    <td>共享使用办法</td>
                    <td>
                        <span v-if="!sharedUseFileList || sharedUseFileList.length < 1">暂无数据</span>
                        <span v-else>
              <div v-for="item in sharedUseFileList" style="padding-top: 8px;">
                 <a class="primary" style="color:#79bbff;" :href="downFile(item.attachCode)">{{ item.attachName }}</a>
              </div>

            </span>
                    </td>
                </tr>
                <tr>
                    <td>机时分配方案</td>
                    <td>
                        <span v-if="!allocationSchemeFileList || allocationSchemeFileList.length < 1">暂无数据</span>
                        <span v-else>
              <div v-for="item in allocationSchemeFileList" style="padding-top: 8px;">
                 <a class="primary" style="color:#79bbff;" :href="downFile(item.attachCode)">{{ item.attachName }}</a>
              </div>
            </span>
                    </td>

                </tr>


                <tr>
                    <td>服务清单</td>
                    <td>
                        <span v-if="!fuwuqindanList || fuwuqindanList.length < 1">暂无数据</span>
                        <el-table v-else
                                  :data="fuwuqindanList"
                                  style="width: 100%">
                            <el-table-column
                                prop="fuwutuanduiData"
                                label="服务团队"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="fuwuneirongData"
                                label="服务内容"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="shoufeibiaozhunData"
                                label="收费标准">
                            </el-table-column>
                        </el-table>

                    </td>

                </tr>
                <tr>
                    <td colspan="2">
                        <button style="float: right;" type="button" class="btn btn-primary" @click="getEquipAdminPhone">
                            获取该仪器相关负责人联系方式
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button style="float: right;" type="button" class="btn btn-primary" @click="apply">申请使用</button>
                        <!--            <span style="float: right;">对该数据有异议？</span>-->
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button style="float: right;" type="button" class="btn btn-primary" @click="sendObjection">
                            发起申述
                        </button>
                        <span style="float: right;">对该数据有异议？</span>
                    </td>
                </tr>
            </table>
            <!-- 仪器数据 展示 END -->

            <!-- 其他资源详情 -->
            <table class="table ins-table" v-else>
                <tr>
                    <td :rowspan="res.img?10:9">基本<br/>信息</td>
                    <td>资源名称</td>
                    <td>{{ res.cname }}</td>
                </tr>
                <tr>
                    <td>标识符</td>
                    <td>{{ res.identifierCode }}</td>
                </tr>
                <tr>
                    <td>学科分类</td>
                    <td>{{ res.subjectType }}</td>
                </tr>
                <tr>
                    <td>主题分类</td>
                    <td>{{ res.themeType }}</td>
                </tr>
                <tr>
                    <td>关键词</td>
                    <td>{{ res.keyWord }}</td>
                </tr>
                <tr>
                    <td>描述</td>
                    <td>{{ res.describeContent }}</td>
                </tr>
                <tr>
                    <td>资源生成日期</td>
                    <td>{{ res.generationDate }}</td>
                </tr>
                <tr>
                    <td>最近发布日期</td>
                    <td>{{ res.releaseDate }}</td>
                </tr>
                <tr>
                    <td>资源信息链接发布地址</td>
                    <td><a :href="res.dataUrl">{{ res.dataUrl }}</a></td>
                </tr>
                <tr v-if="res.img">
                    <td>图片</td>
                    <td><img :src="toImage(res.img)" style="max-width: 500px;"></td>
                </tr>
                <tr>
                    <td rowspan="1">所有者<br/>信息</td>
                    <td>所有者</td>
                    <td>{{ res.ownerName }}</td>
                </tr>

                <tr>
                    <td rowspan="3">共享<br/>方式</td>
                    <td>共享途径</td>
                    <td>
                        <template v-if="res.shareType==0||res.shareType==1">{{
                                res.shareType == 0 ? '线下共享' : '线上共享'
                            }}
                        </template>
                    </td>
                </tr>
                <tr>
                    <td>共享范围</td>
                    <!--                    <td><template v-if="res.shareRange==0||res.shareRange==1">{{res.shareRange==0?'完全共享': '有条件共享'}}</template></td>-->
                    <td>{{ res.shareRangeStr }}</td>
                </tr>
                <tr>
                    <td>申请流程</td>
                    <td>{{ res.applicationProcess }}</td>
                </tr>

                <tr>
                    <td rowspan="5">资源提交<br/>机构信息</td>
                    <td>提交机构名称</td>
                    <td>{{ res.subMechanismName }}</td>
                </tr>
                <tr>
                    <td>提交机构通信地址</td>
                    <td>{{ res.subMechanismAddress }}</td>
                </tr>
                <tr>
                    <td>提交机构邮政编码</td>
                    <td>{{ res.subMechanismPostalCode }}</td>
                </tr>
                <tr>
                    <td>提交机构联系电话</td>
                    <td>{{ res.subMechanismContactNumber }}</td>
                </tr>
                <tr>
                    <td>提交机构电子邮箱</td>
                    <td>{{ res.subMechanismEmail }}</td>
                </tr>

                <tr>
                    <td rowspan="5">资源服务<br/>机构信息</td>
                    <td>服务机构名称</td>
                    <td>{{ res.serviceMechanismName }}</td>
                </tr>
                <tr>
                    <td>服务机构通信地址</td>
                    <td>{{ res.serviceMechanismAddress }}</td>
                </tr>
                <tr>
                    <td>服务机构邮政编码</td>
                    <td>{{ res.serviceMechanismPostalCode }}</td>
                </tr>
                <tr>
                    <td>服务机构联系电话</td>
                    <td>{{ res.serviceMechanismContactNumber }}</td>
                </tr>
                <tr>
                    <td>服务机构电子邮箱</td>
                    <td>{{ res.serviceMechanismEmail }}</td>
                </tr>

                <tr>
                    <td rowspan="5">标识<br/>信息</td>
                    <td>类目名称</td>
                    <td>{{ res.categoryName }}</td>
                </tr>
                <tr>
                    <td>类目代码</td>
                    <td>{{ res.categoryCode }}</td>
                </tr>
                <tr>
                    <td>分类标准名称</td>
                    <td>{{ res.classificationStandardName }}</td>
                </tr>
                <tr>
                    <td>分类标准版本号</td>
                    <td>{{ res.classificationStandardVersionNumber }}</td>
                </tr>
                <tr>
                    <td>科技资源引用标注建议</td>
                    <td>{{ res.proposal }}</td>
                </tr>

                <tr>
                    <td rowspan="2">标识注册<br/>机构信息</td>
                    <td>注册机构名称</td>
                    <td>{{ res.identificationRegisterMechanismName }}</td>
                </tr>
                <tr>
                    <td>注册机构通信地址</td>
                    <td>{{ res.identificationRegisterMechanismAddress }}</td>
                </tr>
                <tr>
                    <td colspan="3">
                        <button style="float: right;" type="button" class="btn btn-primary" @click="getDataAdminPhone">
                            获取该数据相关负责人联系方式
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <button style="float: right;" type="button" class="btn btn-primary" @click="sendObjection">
                            发起申述
                        </button>
                        <span style="float: right;">对该数据有异议？</span>
                    </td>
                </tr>
            </table>
            <!-- 其他资源详情 END -->
        </div>

        <Category style="margin-bottom:42px;" v-loading="categoryLoading" class="ins-category" title="所属机构其他资源"
                  empty="暂无其他资源" :items="hotItems" :catalogType="catalogType" :refresh="true" @refresh="getHotRes">

        </Category>
        <el-dialog v-model="showDialog" title="发起申述">
            <div>
                <Form class="form" ref="target">
                    <div class="form-group">
                        <label class="control-label col-3">申述类型</label>
                        <div class="col-8">
                            <select name="objectionType" id="objectionType" class="form-control" v-model="objectionType"
                                    style="width: 350px;">
                                <option value="业务异议" selected>业务异议</option>
                                <option value="时效异议">时效异议</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">影响</label>
                        <div class="col-8">
                            <select name="objectionInfluence" id="objectionInfluence" class="form-control"
                                    v-model="objectionInfluence" style="width: 350px;">
                                <option value="轻微影响" selected>轻微影响</option>
                                <option value="一般影响">一般影响</option>
                                <option value="严重影响">严重影响</option>
                                <option value="特别严重">特别严重</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">描述</label>
                        <div class="col-8">
              <textarea rows="5" v-model="objectionDescribe" class="form-control" placeholder="请输入描述"
                        style="width: 350px;"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" v-loading="submitLoading" class="btn btn-primary next-btn"
                                @click="tijiao">提交
                        </button>
                    </div>
                </Form>
            </div>
        </el-dialog>

        <el-dialog v-model="applyDialog" title="申请使用">
            <div style="width: 80%;">
                <el-form class="form" label-width="130px">
                    <el-form-item label="仪器名称" style="padding: 10px;">
                        <el-input :disabled="true" v-model="applyParam.instrumentName"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名称" style="padding: 10px;">
                        <el-input :disabled="true" v-model="applyParam.applyUserName"></el-input>
                    </el-form-item>
                    <el-form-item label="用户所在单位" style="padding: 10px;">
                        <el-input maxlength="100" show-word-limit v-model="applyParam.applyUserUnit"></el-input>
                    </el-form-item>
                    <el-form-item label="使用需求" style="padding: 10px;">
                        <el-input type="textarea" maxlength="500" show-word-limit
                                  v-model="applyParam.useRequirements"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" style="padding: 10px;">
                        <el-input maxlength="100" show-word-limit v-model="applyParam.applyUserPhone"></el-input>
                    </el-form-item>
                    <el-form-item style="padding: 10px;">
                        <el-button type="primary" @click="subApply">提交</el-button>
                        <el-button @click="applyDialog = false;">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import Category from '../components/organ/Category'
import {ElPopover} from 'element-plus'
import QrcodeVue from 'qrcode.vue'
import Breads from '../components/common/Bread'
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'

export default {
    data() {
        return {}
    },
    components: {Category, ElPopover, QrcodeVue, Breads},
    setup() {
        const {proxy} = getCurrentInstance();
        const route = useRoute();
        const loading = ref(false)
        const categoryLoading = ref(false)

        const id = ref(route.query.id);
        const catalogType = ref(route.query.catalogType);
        const res = ref({})
        const hotItems = ref([])
        const platformId = ref('');
        const objectionType = ref('');
        const objectionInfluence = ref('');
        const objectionDescribe = ref('');
        const showDialog = ref(false);
        const applyDialog = ref(false);

        const applyParam = ref({
            instrumentId: '',
            instrumentName: '',
            applyUserName: '',
            applyUserUnit: '',
            applyUserPhone: '',
            useRequirements: '',
            platformId: '',
        });

        const submitLoading = ref(false);
        // 共享使用办法
        const sharedUseFileList = ref([])
        // 机时分配方案
        const allocationSchemeFileList = ref([])
        // 服务清单
        const fuwuqindanList = ref([])

        const breads = ref([
            {text: '科技资源', name: 'TechRes', path: '/techRes'},
            {text: '资源详情'}
        ])
        
        const isCollect = ref(false);
        const showShare = ref(false);
        
        const isSelectQQ = ref(false)
        const isSelectWeibo = ref(false)
        const isSelectWechat = ref(false)

        const qrcodeUrl = location.href;

        function getDetail(resId) {
            initCollect(resId); //初始化是否收藏

            loading.value = true;
            const url = '/gdsti2/web/a/webResource/resourceDataInfo';
            proxy.$http.post(url, {id: resId, catalogType: catalogType.value}).then(response => {
                loading.value = false;
                if (response.data.state === 0) {
                    res.value = response.data.result;
                    platformId.value = res.value.platformId;
                }

                //获取最热资源
                getHotRes();
            }).catch(err => {
                loading.value = false;
            })
        }

        //获取最热的前10资源
        function getHotRes() {
            console.log(platformId.value)
            if (!platformId.value) {
                return;
            }

            categoryLoading.value = true;
            const url = '/gdsti2/web/a/webResource/resourceDataList';
            proxy.$http.post(url, {platformId: platformId.value, type: 'hot', noPage: 1, limit: 10}).then(res => {
                categoryLoading.value = false;
                if (res.data.state == 0) {
                    hotItems.value = res.data.result;
                }
            }).catch(err => {
                categoryLoading.value = false;
            })


        }

        function initCollect(dataId) {  //初始化是否收藏
            var userInfo = proxy.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {//登陆了
                //是否收藏
                let collectType = '';
                if(catalogType.value == 'SCIENCE_DATA'){
                    collectType = '1'
                }
                if(catalogType.value == 'INSTRUMENT_DATA'){
                    collectType = '2'
                }
                const param = {
                    noPage: 1,
                    collectType: collectType,
                    dataId: dataId,
                    userCode: userInfo.userCode
                }
                proxy.$http.post('/gdsti2/web/c/webUserCollect/list', param).then(res => {
                    if (res.data.result.length > 0) {
                        isCollect.value = true;
                    } else {
                        isCollect.value = false;
                    }
                }).catch(err => {

                });
            }
        }

        onMounted(() => {
            getDetail(id.value);
        })

        return {
            breads,
            platformId,
            qrcodeUrl,
            isSelectWechat,
            isSelectWeibo,
            isSelectQQ,
            showShare,
            isCollect,
            categoryLoading,
            loading,
            res,
            hotItems,
            getHotRes,
            getDetail,
            id,
            showDialog,
            applyDialog,
            objectionType,
            objectionInfluence,
            objectionDescribe,
            submitLoading,
            sharedUseFileList,
            allocationSchemeFileList,
            fuwuqindanList,
            applyParam,
            proxy,
            catalogType,
        }
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                if (val.query.id && val.path == '/instrument') {
                    this.getDetail(val.query.id);
                }
            },
            deep: true
        }
    },
    mounted() {
    },
    methods: {
        toImage(imgId) {
            return this.$getImage(imgId);
        },
        quickAccessClick() {
            this.$router.push({name: 'Building', path: 'building'})
        },
        //收藏图标点击
        collectClick() {

            var userInfo = this.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {//登陆了
                let collectType = '';
                if(this.catalogType == 'SCIENCE_DATA'){
                    collectType = '1'
                }
                if(this.catalogType == 'INSTRUMENT_DATA'){
                    collectType = '2'
                }
                const param = {
                    operateType: this.isCollect ? 'cancel' : 'add',
                    collectType: collectType,
                    dataId: this.id,
                    userCode: userInfo.userCode,
                }

                this.$http.post('/gdsti2/web/c/webUserCollect/collectOperate', param).then(res => {
                    if (res.data.state == 0) {

                        if(!this.isCollect){
                            this.ElMessage.success("收藏成功，可在个人中心=》我的收藏 中查看收藏记录");
                        }else{
                            this.ElMessage.success("取消收藏成功");

                        }
                        this.isCollect = !this.isCollect;
                    }
                }).catch(err => {

                })

            } else {
                this.ElMessage.error("请先登录");
                return;
            }

        },
        shareClick() {
            this.showShare = !this.showShare;
        },
        qqClick() {
            const url = location.href;
            var shareqqstring = 'https://connect.qq.com/widget/shareqq/index.html?url=' + encodeURIComponent(url) + '&title=广东省科技资源共享服务平台' + '&desc=' + this.res.cataloguetNameCn;
            window.open(shareqqstring);
            recordShare(1)
        },
        weiboClick() {
            const url = location.href;
            var sharesinastring = 'http://service.weibo.com/share/share.php?url=' + encodeURIComponent(url) + '&title=广东省科技资源共享服务平台' + '&content=' + this.res.cataloguetNameCn + '&sourceUrl=' + url;
            window.open(sharesinastring);
            recordShare(2)
        },
        wechatClick() {
            const url = location.href;
            recordShare(3)
        },
        recordShare(type) {
            //记录分享途径
            const title = this.res.cataloguetNameCn;
            const dept = this.res.fromProjectName;
            const url = '';
            // this.$http.post(url, {}).then(res=>{}).catch(err=>{})
        },
        getEquipAdminPhone() {
            // return this.$upgrading();

            var userInfo = this.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {
                var that = this;
                const url = '/gdsti2/resource/c/resDataFormInstrument/getInstrumentAdmin';
                this.$http.post(url, {id: that.id}).then(res => {
                    that.categoryLoading = false;
                    if (res.data.state == 0) {
                        that.ElMessage.success(res.data.result);
                    }
                }).catch(err => {
                    that.categoryLoading = false;
                })
            } else {
                this.ElMessage.error("请先登录");
            }

        },
        downFile(attachCode) {
            return "/hf/base/a/fileManage/downloadFile?attachCode=" + attachCode;
        },
        getDataAdminPhone() {
            var userInfo = this.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {
                var that = this;
                const url = '/gdsti2/resource/c/resDataFormScience/getScienceAdmin';
                this.$http.post(url, {id: that.id}).then(res => {
                    that.categoryLoading = false;
                    if (res.data.state == 0) {
                        that.ElMessage.success(res.data.result);
                    }
                }).catch(err => {
                    that.categoryLoading = false;
                })
            } else {
                this.ElMessage.error("请先登录");
            }
        },
        subApply() {
            // return this.$upgrading();
            const that = this;
            HFConfirm("确认要提交吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    const url = '/gdsti2/resource/c/resInstrumentUseApply/edit';
                    that.loading = false;
                    that.proxy.$http.post(url, that.applyParam).then(res => {
                        if (res.data.state == 0) {

                            that.applyDialog = false;
                            that.ElMessage.success("提交成功！");
                        }
                        that.loading = false;
                    }).catch(err => {
                        that.loading = false;
                        that.ElMessage.error("提交失败，请联系网站管理员处理。");
                    });
                }
            });

        },
        apply() {

            // return this.$upgrading(); 升级中
            const userInfo = this.$store.state.userInfo;
            console.log('userInfo',userInfo);
            if (userInfo && userInfo.userCode) {
                this.applyParam.instrumentName = this.res.cname;
                this.applyParam.instrumentId = this.id;
                this.applyParam.applyUserName = userInfo.userName;
                this.applyParam.applyUserUnit = userInfo.unitName;
                this.applyParam.applyUserPhone = userInfo.mobilePhone;
                this.applyParam.platformId = this.res.platformId;
                this.applyDialog = true;
            }else{
                this.ElMessage.error("请先登录");
            }

        },
        sendObjection() {
            return this.$upgrading();
            var token = this.$store.state.token;
            if (token) {
                this.showDialog = true;

                return;
            } else {
                this.ElMessage.error("请先登录");
                return;
            }
        },
        tijiao() {
            var that = this;

            var dataTpye = 0;

            var objectionTitle = this.res.cataloguetNameCn;
            if (this.res.isOtherTableData) {
                //仪器
                dataTpye = 1;
                objectionTitle = this.res.dataList[0].fieldData;
            }
            var data = {
                token: this.$store.state.token,
                objectionTitle: objectionTitle,
                objectionType: this.objectionType,
                objectionInfluence: this.objectionInfluence,
                objectionDescribe: this.objectionDescribe,
                dataPlatform: this.platformId,
                dataId: this.id,
                dataTpye: dataTpye,
            };
            const url = '/hfapplication/gdsti/a/objection/addObjection';
            this.$http.post(url, data).then(res => {
                if (res.data.state == 0) {
                    console.log(res);
                    this.showDialog = false;
                    that.ElMessage.success("提交成功！");
                }
            }).catch(err => {
                that.ElMessage.error("提交失败，请联系网站管理员处理。");
            })
        }


    }
}
</script>

<style lang="less" scoped>
.ins-content {
    display: flex;

    .ins-category {
        width: 283px;
        margin-top: 37px;
    }

    .ins {
        flex: 1;
        margin-right: 33px;

        .ins-title {
            justify-content: space-between;
            display: flex;
            font-family: SourceHanSansCN-Bold;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            align-items: center;
            line-height: 26px;

            .title-box {
                .title {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                }
            }

            .quick-access {
                cursor: pointer;
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                line-height: 14px;
                color: #007EEC;
                margin-left: 10px;
                display: flex;
                align-items: center;

                .access-icon {
                    margin-right: 0px;
                }
            }
        }

        .dept {
            font-family: SourceHanSansCN-Normal;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            margin-top: 16px;

            .dept-name {
                font-family: SourceHanSansCN-Normal;
                font-size: 14px;
                color: #007EEC;
            }
        }
    }

    .ins-table {
        margin-top: 16px;
        margin-bottom: 42px;
        border-right: 1px solid #c0e1fe;;
        border-top: 1px solid #c0e1fe;;
        border-left: 1px solid #c0e1fe;

        tr td {
            background: rgb(241, 248, 255);
            text-align: right;
            padding: 16px;
            border-bottom: 1px solid #c0e1fe;;
        }

        tr td[rowspan] {
            background: rgb(0, 127, 236);
            color: #fff;
            text-align: center;
            border-bottom: 1px solid #fff;
            width: 74px;
        }


        tr td:last-child {
            background: #fff;
            text-align: left;
            width: 540px;
        }

    }
}

.tool-box {
    float: right;
    display: flex;
    position: relative;

    .tool-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 24px;
        cursor: pointer;

        .tool-icon {
            height: 24px;
        }

        .tool-text {
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            line-height: 12px;
            color: #000000;
            margin-top: 8px;
        }
    }

    .share-list {
        position: absolute;
        top: 46px;
        left: 56px;
        display: flex;

        .share-item {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 32px;
            background-color: #fff;
            border-width: 1px;
            border-style: solid;
            cursor: pointer;
            margin: 4px;

            .share-icon {
                width: 20px;
            }
        }

        .share-item-qq {
            border-color: #56b6e7;
        }

        .share-item-qq:hover {
            background-color: #56b6e7;
        }

        .share-item-weibo {
            border-color: #ff763b;
        }

        .share-item-weibo:hover {
            background-color: #ff763b;
        }

        .share-item-wechat {
            border-color: #7bc549;
        }

        .share-item-wechat:hover {
            background-color: #7bc549;
        }


    }
}

.qrcode-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .help {
        text-align: center;
        font-size: 12px;
        line-height: 18px;

    }

    .qrcode-img {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.form-group {
    justify-content: center;
    margin-bottom: 12px;
}
</style>

